@import "assets/css/primeng-theme.css";
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.css";
@import "../node_modules/primeicons/primeicons.css";
@import 'quill/dist/quill.core.css';
@import 'quill/dist/quill.bubble.css';
@import 'quill/dist/quill.snow.css';
@import "vhd-quill-emoji/dist/quill-emoji.css";
@import "assets/scss/joyride";

*, *:before, *:after {
  box-sizing: border-box;
}

html {
  margin: 0;
  overflow: hidden;
  padding: 0;
}

body {
  background: var(--bluegray-50);
  color: var(--text-color);
  font-weight: 300;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}

/* PrimeNG Overrides */
.p-card .p-card-content {
  padding: 0;
}

.p-card .p-card-body {
  padding: 2rem;
}

html, body {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

.message-content {
  a,
  a:visited,
  a:active {
    color: var(--text-color);
  }
}

.comment {
  .message {
    a {
      color: var(--text-color);
    }
  }
}

.onesignal-customlink-subscribe.button.small {
  font-family: var(--font-family) !important;
  padding: 0.7rem 0.8rem !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}

.onesignal-customlink-container {
  min-height: auto !important;
}

.quill-editor-box-class{
  width: 100%;
  height: 25vh;
}

quill-editor .ql-container.ql-snow .ql-editor{
  border-radius: 0px;
}
